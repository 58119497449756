<template>
    <h1>The Autosaved Podcast Archive</h1>

    <div class="column-it-baby">
        <h2>The Autosaved Podcast</h2>
        <p>We are no longer recording out podcast, but you can listen to the archived episodes below.</p>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+77+-+Covink.mp3">Episode 77 - Covink.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+76+-+Sheep+Cerebro.mp3">Episode 76 - Sheep Cerebro.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+75+-+Cancel+Mando.mp3">Episode 75 - Cancel Mando.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+74+-+Geordi+4+Jeopardy.mp3">Episode 74 - Geordi 4 Jeopardy.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+73+-+Is+Harry+Potter+EVIL.mp3">Episode 73 - Is Harry Potter EVIL.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+72+-+Elmando.mp3">Episode 72 - Elmando.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+71+-+Moon+Alone.mp3">Episode 71 - Moon Alone.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+70+-+The+Bored+Board+Game+Game.mp3">Episode 70 - The Bored Board Game Game.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+69+-+Devates.mp3">Episode 69 - Devates.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+68+-+The+Fat+Bear+Bully.mp3">Episode 68 - The Fat Bear Bully.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+67+-+Old+Games+New+Tricks.mp3">Episode 67 - Old Games New Tricks.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+66+-+Dont+Wife+Your+Pig.mp3">Episode 66 - Dont Wife Your Pig.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+65+-+First+Jobs.mp3">Episode 65 - First Jobs.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+64+-+Live+from+the+Internet.mp3">Episode 64 - Live from the Internet.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+63.5+-+Our+Best+Bits+Episodes+1+-+7.mp3">Episode 63.5 - Our Best Bits Episodes 1 - 7.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+63+-+Pop+Culture+Attacks+on+God.mp3">Episode 63 - Pop Culture Attacks on God.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+62+-+100+Bad+Segues.mp3">Episode 62 - 100 Bad Segues.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+61+-+Vacations.mp3">Episode 61 - Vacations.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+60+-+Classic+Internets.mp3">Episode 60 - Classic Internets.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+59+-+Software+Piracy+on+the+High+Seas.mp3">Episode 59 - Software Piracy on the High Seas.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+58+-+This+is+Your+Captain+Speaking.mp3">Episode 58 - This is Your Captain Speaking.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+57+-+Sudopoo.mp3">Episode 57 - Sudopoo.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+56+-+Multivitamanager.mp3">Episode 56 - Multivitamanager.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+55+-+Explain+a+Game+Badly.mp3">Episode 55 - Explain a Game Badly.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+54+-+Credit+Card+Churning.mp3">Episode 54 - Credit Card Churning.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+53+-+What+Pet+is+Best.mp3">Episode 53 - What Pet is Best.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+52+-+EMOJINCY.mp3">Episode 52 - EMOJINCY.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+51+-+Passionate+Petty+Preferences.mp3">Episode 51 - Passionate Petty Preferences.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+50+-+How+to+Podcast+Good.mp3">Episode 50 - How to Podcast Good.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+49+-+DD+and+Other+Tabletop+RPGs.mp3">Episode 49 - DD and Other Tabletop RPGs.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+48+-+Conspiracy+Theories.mp3">Episode 48 - Conspiracy Theories.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+47+-+Gaming+on+the+Cheap.mp3">Episode 47 - Gaming on the Cheap.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+46+-+Groot+Beats.mp3">Episode 46 - Groot Beats.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+45+-+One+Sneeze+Is+Enough.mp3">Episode 45 - One Sneeze Is Enough.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+44+-+A+Whole+Lot+of+Splashin.mp3">Episode 44 - A Whole Lot of Splashin.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+43+-+Tiger+King+Trivia.mp3">Episode 43 - Tiger King Trivia.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+42+-+Autosaved+After+Dark.mp3">Episode 42 - Autosaved After Dark.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+41+-+Your+Wife+is+Fake.mp3">Episode 41 - Your Wife is Fake.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+40+-+Ad-Hoc.mp3">Episode 40 - Ad-Hoc.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+39+-+Wiistation.mp3">Episode 39 - Wiistation.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+38+-+Megamecca.mp3">Episode 38 - Megamecca.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+37+-+Googletoodledo.mp3">Episode 37 - Googletoodledo.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+36+-+Taste+the+Gravity.mp3">Episode 36 - Taste the Gravity.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+35+-+helth.mp3">Episode 35 - helth.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+34+-+Urinal+Limbo.mp3">Episode 34 - Urinal Limbo.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+33+-+The+War+on+Monocles.mp3">Episode 33 - The War on Monocles.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+32+-+Extra+Life+Finds+a+Way.mp3">Episode 32 - Extra Life Finds a Way.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+31+-+The+Most+Controversial+Episode+Ever.mp3">Episode 31 - The Most Controversial Episode Ever.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+30+-+Just+My+Brain.mp3">Episode 30 - Just My Brain.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+29+-+Gatekeeping+Christmas.mp3">Episode 29 - Gatekeeping Christmas.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+28+-+Putting+the+Jesus+Back+in+Jesusmas.mp3">Episode 28 - Putting the Jesus Back in Jesusmas.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+27+-+The+OG+OT.mp3">Episode 27 - The OG OT.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+26+-+Our+Superpowers.mp3">Episode 26 - Our Superpowers.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+25+-+Kangaroos+Now+Extinct+After+Last+One+Hit+By+VR-Wearing+Cow+Driving+Tesla+Cybertruck.mp3">Episode 25 - Kangaroos Now Extinct After Last One Hit By VR-Wearing Cow Driving Tesla Cybertruck.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+24+-+Interesting+Dice.mp3">Episode 24 - Interesting Dice.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+23+-+Just+Like+Real+Life.mp3">Episode 23 - Just Like Real Life.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+22+-+Milk+Hoses.mp3">Episode 22 - Milk Hoses.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+21+-+Kanyunity.mp3">Episode 21 - Kanyunity.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+20+-+The+Ultimate+Geek.mp3">Episode 20 - The Ultimate Geek.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+19+-+Pinky+Pecs.mp3">Episode 19 - Pinky Pecs.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+18+-+Chime+Time+feat+Cliff.mp3">Episode 18 - Chime Time feat Cliff.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+17+-+The+Cough+Tapes.mp3">Episode 17 - The Cough Tapes.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+16+-+Kidz+in+da+Frunk.mp3">Episode 16 - Kidz in da Frunk.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+15+-+Puppy+Paper.mp3">Episode 15 - Puppy Paper.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+14+-+Cell+Heaven.mp3">Episode 14 - Cell Heaven.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+13+-+Schr&#xF6;dingers+Decibel.mp3">Episode 13 - Schr&#xF6;dingers Decibel.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+12+-+Infinite+Black+Widows.mp3">Episode 12 - Infinite Black Widows.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+11+-+Uncle+Circles.mp3">Episode 11 - Uncle Circles.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+10+-+MAXIMUM+FEDGE.mp3">Episode 10 - MAXIMUM FEDGE.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+09+-+30-50+Feral+Talking+Cats.mp3">Episode 09 - 30-50 Feral Talking Cats.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+08+-+WHAAAAT.mp3">Episode 08 - WHAAAAT.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+07+-+Nervous+Energy.mp3">Episode 07 - Nervous Energy.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+06+-+Internetting+Internetters.mp3">Episode 06 - Internetting Internetters.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+05+-+You+Cant+Hug+Your+Kids+With+Nuclear+Arms.mp3">Episode 05 - You Cant Hug Your Kids With Nuclear Arms.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+04+-+Yes+And.mp3">Episode 04 - Yes And.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+03+-+Breaking+News+Listen+or+Else.mp3">Episode 03 - Breaking News Listen or Else.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+02+-+Back+it+Up+or+Back+Down.mp3">Episode 02 - Back it Up or Back Down.mp3</a>
        <a href="https://archivedpodcast.s3.us-east-2.amazonaws.com/Episode+01+-+Need+for+Speed+(Cubes).mp3">Episode 01 - Need for Speed (Cubes).mp3</a>
        <br />
        <h2>The Diamond Cast</h2>
        <p>Want to hear an even older podcast we did? Check out The Diamond Cast episodes below! (The original episodes are on <a href="https://thediamondcast.tumblr.com/">tumblr</a>).</p>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/01_DiamondCast_Just_Do_It.mp3">01_DiamondCast_Just_Do_It.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/02_diamondCast_Not_So_Hot_Problems.mp3">02_diamondCast_Not_So_Hot_Problems.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/03_diamondCast_Delicious_Bronies.mp3">03_diamondCast_Delicious_Bronies.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/04_diamondCast_The_Half_Fonz.mp3">04_diamondCast_The_Half_Fonz.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/05_diamondCast_Elder_Scrolls_of_Warcraft.mp3">05_diamondCast_Elder_Scrolls_of_Warcraft.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/06_diamondCast_Panem_vs_Pandora.mp3">06_diamondCast_Panem_vs_Pandora.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/07_diamondCast_Slow_Mo_Sliver_Hunting.mp3">07_diamondCast_Slow_Mo_Sliver_Hunting.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/08_diamondCast_Session_Capm_Cache_and_Tatarrrrr.mp3">08_diamondCast_Session_Capm_Cache_and_Tatarrrrr.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/09_diamondCast_Session_Obamney_Vs_Rombama.mp3">09_diamondCast_Session_Obamney_Vs_Rombama.mp3	</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/10_diamondCast_Yo_Daddys_Innanet.mp3">10_diamondCast_Yo_Daddys_Innanet.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/11_diamondCast_Nose_Turkey.mp3">11_diamondCast_Nose_Turkey.mp3</a>
        <a href="https://autosaved-thediamondcast.s3.us-east-2.amazonaws.com/12_diamondCast_Airbud_2_Age_of_Ultron.mp3">12_diamondCast_Airbud_2_Age_of_Ultron.mp3</a>
        <br />
        <h2>Other Audio</h2>
        <p>Here are a couple of other silly things you may want to check out.</p>
        <a href="https://autosaved-randomfiles.s3.us-east-2.amazonaws.com/therealepic.mp3">"The Real Epic", a parody of Eminem's "The Real Slim Shady"</a>
        <a href="https://autosaved-randomfiles.s3.us-east-2.amazonaws.com/rob_rap_rendered.mp3">Rob's rap from our board game podcast episode</a>
    </div>
</template>

<style scoped>

.column-it-baby{
    display: flex;
    flex-direction: column;
}

</style>